import Partials from 'components/partials/Partials';
import type { PartialWidgetContext } from 'types/cms';
import { getKey } from 'utils/idGenerator';
import type { GroupGridProps } from '../group-grid-default/group-grid-default';
import styles from './group-grid-fixed-sidebar.module.scss';

const GroupGridFixedSidebar = ({ context }: { context: GroupGridProps }) => (
  <div className={styles.grid}>
    {context.children.map((widget: PartialWidgetContext) => (
      <Partials key={getKey(widget.id, widget)} context={widget} />
    ))}
  </div>
);
export default GroupGridFixedSidebar;
